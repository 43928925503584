<template>
  <div>
    <el-row>
      <el-form label-width="100px" style="margin-top: 10px;margin-left: 10px" :model="form" :rules="rules" ref="form">
        <el-row>
          <el-col :span="5">
            <el-form-item label="客户名" prop="name">
              <el-input v-model="form.name" placeholder="没有请填暂无" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="客户城市" prop="city">
              <el-input v-model="form.city" placeholder="请输入客人所在城市" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="手机号">
              <el-input v-model="form.phone" placeholder="请输入手机号" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="微信号">
              <el-input v-model="form.weChat" placeholder="请输入微信号" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="客资来源" prop="source">
              <el-cascader
                  :props="{ checkStrictly: true,
                            expandTrigger: 'hover'}"
                  multiple="false"
                  :show-all-levels="false"
                  style="width: 100%;"
                  :options="sourceArray"
                  v-model="form.source"
                  ref="source"
                  @change="cascaderClsoe"
                  clearable></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="对接日期">
              <el-date-picker type="date" placeholder="对接日期"
                              v-model="form.createDate"
                              style="width: 100%;"
                              value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="婚期">
              <el-input v-model="form.weddingDay" placeholder="请输入婚期"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="婚礼地点">
              <el-input v-model="form.weddingVenue" placeholder="请输入婚礼地点"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="客服" prop="service">
              <el-select placeholder=请选择 v-model="form.service" style="width: 100%;">
                <el-option
                    v-for="item in serviceArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="备注">
              <el-input
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 4}"
                  placeholder="请输入内容"
                  v-model="form.remark">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button type="primary" @click="cusAddSubmit('form')">立即提交</el-button>
          <el-button type="warning" @click="reset('form')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "cus-add",
  created() {
    this.pageInit()
    console.log("....")
  },
  data() {
    return {
      form: {
        name: "",
        city: "",
        phone: "",
        weChat: "",
        source: "",
        createDate: this.$dateUtils.dateToYMDString(new Date()),
        weddingDay: "",
        weddingVenue: "",
        service: "",
        remark: "",
        tenantCrop: localStorage.getItem("tenantCrop")
      },
      rules: {
        name: [{required: true, message: '请输入客户名', trigger: 'blur'}],
        city: [{required: true, message: '请输入用户所在城市', trigger: 'blur'}],
        source: [{required: true, message: '请选择客资来源', trigger: 'change'}],
        service: [{required: true, message: '请选择客服', trigger: 'change'}],
      },
      //客服
      serviceArray: [],
      //客资来源
      sourceArray: [],
    }
  },
  methods: {
    //重置
    reset(form) {
      this.$refs[form].resetFields();
    },
    //客资添加
    cusAddSubmit(form) {
      this.form.source = this.form.source[this.form.source.length - 1];
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$alert("客资名称：" + this.form.name
                          + "<br>客资来源："+ this.$refs.source.getCheckedNodes()[0].label
                          + "<br>确认添加吗？",
              "添加客资",
              {
                dangerouslyUseHTMLString: true,
                type: "success",
                center: true,
                showCancelButton: true
          }).then(() => {
            this.$axios({
              method: "POST",
              url: "/customer/saveCustomer",
              params: this.form
            }).then(response => {
              let flag = response.data.code === 200
              this.$message({
                showClose: true,
                message: flag ? '客资添加成功': response.data.msg,
                type: flag ? 'success':'error',
                duration: 1000,
              });
              this.reset('form')
            })
          }).catch(() => {
          })
        } else {
          return false;
        }
      });
    },
    pageInit() {
      this.$selectUtils.querySourceIds(this.$selectUtils.cascader).then(response => {
        this.sourceArray = JSON.parse(JSON.parse(response.data.data))
      })
      this.$selectUtils.queryServiceIds().then(response => {
        this.serviceArray = JSON.parse(response.data.data);
      })
    },
    //级联选择关闭
    cascaderClsoe() {
      this.$refs.source.dropDownVisible = false;
    }
  },
}
</script>

<style scoped>
</style>